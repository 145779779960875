<template>
  <div class="rollBox">
    <div class="roll" :class="[controlDiv === 1 ? 'roll1' : '' , controlDiv === 2 ? 'roll2' : '' , controlDiv === 3 ? 'roll3' : '' , controlDiv === 4 ? 'roll4' : '' , controlDiv === 5 ? 'roll5' : '' ,]">
      <div class="rollContent">
        <div class="showChild" v-if="controlDiv === 1">
          <div>
            <span>
              黃金交易平台
            </span>
            <p>
              香港红狮集团，属贸易场 AA 类会员，為客戶提供黃金交易平台
            </p>
          </div>
        </div>
        <div class="showChild" v-if="controlDiv === 2">
          <div>
            <span>资金安全</span>
            <p>
              出入金顺畅，资金安全有保障，可在APP或用户中心即时查看资金变动的具体情况
            </p>
          </div>
        </div>
        <div class="showChild" v-if="controlDiv === 3">
          <div>
            <span>服务专业</span>
            <p>
              7*24小时专业客服，随时解决您任何投资疑问
            </p>
          </div>
        </div>
        <div class="showChild" v-if="controlDiv === 4">
          <div>
            <span>丰富活动</span>
            <p>
              享优越点差，省即是赚，同时还有丰富的新用户注<br/>
              册活动，享赠金好礼
            </p>
          </div>
        </div>
        <div class="showChild" v-if="controlDiv === 5">
          <div>
            <span>强大软件</span>
            <p>
              全新智能化软件，独家开发交易利器，超强下单执<br/>
              行能力，是您交易的强大后盾
            </p>
          </div>
        </div>
      </div>
      <div class="rollControl" @mouseleave="tabLeave()">
        <ul @mouseover="tabClick(1)"
            :class="controlDiv === 1 ? 'ulBottom' : ''">
          <li>
            <span class="rollshu" v-if="controlDiv === 1"></span>
            <span>合规平台</span>
          </li>
          <li>香港贸易场081行员</li>
        </ul>
        <ul @mouseover="tabClick(2)"
            :class="controlDiv === 2 ? 'ulBottom' : ''">
          <li>
            <span class="rollshu" v-if="controlDiv === 2"></span>
            <span>资金安全</span>
          </li>
          <li>出金自由</li>
        </ul>
        <ul @mouseover="tabClick(3)"
            :class="controlDiv === 3 ? 'ulBottom' : ''">
          <li>
            <span class="rollshu" v-if="controlDiv === 3"></span>
            <span>服务专业</span>
          </li>
          <li>7*24小时客服</li>
        </ul>
        <ul @mouseover="tabClick(4)"
            :class="controlDiv === 4 ? 'ulBottom' : ''">
          <li>
            <span class="rollshu" v-if="controlDiv === 4"></span>
            <span>丰富活动</span>
          </li>
          <li>超优点差，活动福利多</li>
        </ul>
        <ul @mouseover="tabClick(5)"
            :class="controlDiv === 5 ? 'ulBottom' : ''">
          <li>
            <span class="rollshu" v-if="controlDiv === 5"></span>
            <span>强大软件</span>
          </li>
          <li>全新智能软件化</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'roll',
  data(){
    return {
      // 控制serveBox
      controlDiv:1,
      // 定时器
      timer:Function,
    }
  },
  created(){
    // 每4秒自动切换rollBox
    this.timer = setInterval(this.updateDiv, 4000);
  },
  methods:{
    // tabbar鼠标移入事件
    tabClick(attr){
      clearInterval(this.timer);
      switch(attr){
        case 1:
          this.controlDiv = attr;
          break;
        case 2:
          this.controlDiv = attr;
          break;
        case 3:
          this.controlDiv = attr;
          break;
        case 4:
          this.controlDiv = attr;
          break;
        case 5:
          this.controlDiv = attr;
          break;
      }
    },
    // tabLeave鼠标离开事件
    tabLeave(){
      this.timer = setInterval(this.updateDiv, 4000);
    },
    // 切换rollBox的方法
    updateDiv(){
      if(this.controlDiv < 5){
        this.controlDiv = this.controlDiv + 1;
      }else{
        this.controlDiv = 1;
      } 
    },
  }
}
</script>

<style lang="less" scoped>
  .rollBox{
    width: 100%;
    position: relative;
    .roll1{
      background: url('../../../static/index/rollBanner1.png') no-repeat;
      background-size: 100% 100%;
    }
    .roll2{
      background: url('../../../static/index/rollBanner2.png') no-repeat;
      background-size: 100% 100%;
    }
    .roll3{
      background: url('../../../static/index/rollBanner3.png') no-repeat;
      background-size: 100% 100%;
    }
    .roll4{
      background: url('../../../static/index/rollBanner4.png') no-repeat;
      background-size: 100% 100%;
    }
    .roll5{
      background: url('../../../static/index/rollBanner5.png') no-repeat;
      background-size: 100% 100%;
    }
    .roll{
      width: 1224px;
      height: 465px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .rollContent{
        width: 80%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        box-sizing: border-box;
        padding: 0 0 66px 40px;
        .showChild{
          div{
            color: #ffffff;
            span{
              font-size: 36px;
              letter-spacing: 2px;
            }
            p{
              color: #edefec;
              letter-spacing: 2px;
              line-height: 26px;
              margin-top: 20px;
            }
          }
        }
      }
      .rollControl{
        width: 240px;
        height: 100%;
        box-sizing: border-box;
        ul{
          width: 100%;
          height: 19.5%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          margin-bottom: 3px;
          background-color: rgb(24 25 26 / 90%);
          box-sizing: border-box;
          padding-left: 60px;
          li{
            font-size: 13px;
            color: #dbdddc;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            letter-spacing: 2px;
            span{
              display: inline-block;
              font-size: 16px;
              margin-bottom: 10px;
            }
            .rollshu{
              width: 3px;
              height: 25px;
              background-color: #ffffff;
              margin-bottom: 5px;
              margin-right: 8px;
            }
          }
        }
        ul:nth-child(1){
          border-top-right-radius: 15px;
        }
        ul:nth-child(5){
          border-bottom-right-radius: 15px;
        }
        .ulBottom{
          background-color: transparent;
          li{
            color: #858080;
            display: flex;
            justify-content: flex-start;
            align-items: center;
          }
        }
      }
    }
  }
  @media screen and (max-width: 500px) {
    .rollBox{
      .roll1{
        background: url('../../../static/index/wapImg/roll1.jpg') no-repeat;
        background-size: cover;
      }
      .roll2{
        background: url('../../../static/index/wapImg/roll2.jpg') no-repeat;
        background-size: cover;
      }
      .roll3{
        background: url('../../../static/index/wapImg/roll3.jpg') no-repeat;
        background-size: cover;
      }
      .roll4{
        background: url('../../../static/index/wapImg/roll4.jpg') no-repeat;
        background-size: cover;
      }
      .roll5{
        background: url('../../../static/index/wapImg/roll5.jpg') no-repeat;
        background-size: cover;
      }
      .roll{
        width: 97%;
        height: 380px;
        border-radius: 15px;
        .rollContent{
          padding: 0 0 30px 20px;
          .showChild{
            div{
              color: #ffffff;
              span{
                font-size: 20px;
                letter-spacing: 0px;
              }
              p{
                color: #edefec;
                letter-spacing: 0px;
                line-height: 26px;
                margin-top: 20px;
                font-size: 14px;
              }
            }
          }
        }
        .rollControl{
          width: 180px;
          ul{
            height: 19.4%;
            padding-left: 10px;
            li{
              font-size: 13px;
              letter-spacing: 0;
              span{
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }
</style>